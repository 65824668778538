.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 1px dashed var(--border-color-input);
    background: var(--bg-color-input);
    border-radius: 6px;
    cursor: pointer;
    transition: border-color 0.3s;
    position: relative;
}

.upload-container:hover {
    border-color: #1890ff;
}

.upload-container .loader {
    top: 52px;
    left: 52px;
}

.upload-select {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload-content {
    text-align: center;
    color: var(--fill-icons);
    transition: all 0.3s;
}

.upload-container:hover .upload-content {
    color: var(--fill-icons-hover);
}

.upload-content .icon-plus svg {
    transform: rotate(45deg);
    width: 28px;
    height: auto;
}

.upload-content .icon-plus svg path {
    fill: var(--fill-icons);
    transition: all 0.3s;
}

.upload-container:hover .icon-plus svg path {
    fill: var(--fill-icons-hover);
}
