.custom-btn {
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.135s ease-in-out;
    border: 1px solid red;
    font-size: 15px;
    font-weight: 500;
    padding: 9.6px 20px;
    border-radius: 20px;
    cursor: pointer;
    width: max-content;
    position: relative;
}

.custom-btn:disabled {
    opacity: 0.65;
    cursor: default;
}

.custom-btn.is-loading span {
    opacity: 0;
}

.custom-btn.small {
    padding: 6.5px 14px;
    font-size: 13px;
}

.custom-btn.without-rounded {
    border-radius: 6px;
}

/* SUCCESS */

.custom-btn.success {
    color: var(--color-currency);
    border-color: var(--color-currency);
}

.custom-btn:not(:disabled).success:hover {
    background-color: #2e3f4c;
}
.light .custom-btn:not(:disabled).success:hover {
    background-color: #eefbf3;
}

/* ERROR */

.custom-btn.error {
    color: #ea5455;
    border-color: #ea5455;
}

.custom-btn:not(:disabled).error:hover {
    background-color: #3e364a;
}

.light .custom-btn:not(:disabled).error:hover {
    background-color: #fdf1f1;
}

/* WARNING */

.custom-btn.warning {
    color: var(--secondary-text-color);
    border-color: #ff9f43;
}

.custom-btn:not(:disabled).warning:hover {
    background-color: #403c49;
}

.light .custom-btn:not(:disabled).warning:hover {
    background-color: #fff7f0;
}
