@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700;800&display=swap");

ol, ul {
    list-style: none;
}

:focus {
    outline: 0;
}

::-webkit-input-placeholder {
    text-indent: 0px;
    -webkit-transition: text-indent 0.3s ease;
    transition: text-indent 0.3s ease;
    font-family: inherit;
    color: #aaa !important;
}

::-moz-placeholder {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    font-family: inherit;
    color: #aaa !important;
}

:-moz-placeholder {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    font-family: inherit;
    color: #aaa !important;
}

:-ms-input-placeholder {
    text-indent: 0px;
    -webkit-transition: text-indent 0.3s ease;
    transition: text-indent 0.3s ease;
    font-family: inherit;
    color: #aaa !important;
}

:focus::-webkit-input-placeholder {
    text-indent: -1000px;
    -webkit-transition: text-indent 0.3s ease;
    transition: text-indent 0.3s ease;
}

:focus::-moz-placeholder {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

:focus:-moz-placeholder {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

:focus:-ms-input-placeholder {
    text-indent: -1000px;
    -webkit-transition: text-indent 0.3s ease;
    transition: text-indent 0.3s ease;
}

#start-page table {
    border-collapse: collapse;
    border-spacing: 0;
}

#start-page label, #start-page input:not([type="checkbox"]),
#start-page input:not([type="radio"]), #start-page button {
    -webkit-appearance: none;
    font-family: inherit;
}

#start-page label, #start-page input[type="submit"], #start-page button {
    cursor: pointer;
}

#start-page img {
    border: none;
}

html, body {
    height: 100%;
}

html {
    font-size: 62.5%;
}

#start-page button:focus {
    outline: none !important;
}

#start-page input::-webkit-outer-spin-button,
#start-page input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#start-page input:hover::-webkit-outer-spin-button,
#start-page input:hover::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

/* @include transform-translate(#, #); */
/* @include transform-translateY(#); */
/* @include transform-translateX(#); */
/* @include transform-translate3d(#, #, #); */
/* @include transform-rotate(#); */
/* @include transform-scale(#); */
/* @include animation(#); */
/* @include animation-delay(#); */
/* @include animation-duration(#); */
/* @include animation-fill-mode(#); */
/* @include animation-name(#); */
/* @include transition(#); */
/* @include transition-transform(#, #); */
/* @include transition-transform-cubic-bezier(#, #, #, #, #); */
/* @include transition-timing-function(#); */
/* @include transition-duration(#); */
/* @include transition-delay(#); */
/* @include backface-visibility(#); */
/* @include transition-opacity-visibility(#, #); */
/* @include transition-opacity(#); */
/* @include box-shadow(#); */
/* @include opacity(#); */
/* @include border-radius(#); */
/* @include border_b_l-radius(#); */
/* @include border_b_r-radius(#); */
/* @include border_t_r-radius(#); */
/* @include border_t_l-radius(#); */
/* @include box-sizing(#); */
/* @include user-select(#); */
/* @include filter-blur-brightness(#, #); */
/* @include filter-img(#, #); */
/* @include filter(#); */
/* @include box-sizing(#, #(0)); */
.collapse:not(.show) {
    display: none;
}

.collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        -webkit-transition: none;
        transition: none;
    }
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.modal__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border: none;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    width: 2rem;
    height: 2rem;
    background: url(../../public/sp_img/close-inversion.svg) 0 0 no-repeat;
    background-size: contain;
}

.modal__close:hover {
    opacity: 0.5;
}

.modal__title {
    margin-bottom: 2rem !important;
}

.modal-dialog {
    position: relative;
    pointer-events: none;
}

@media only screen and (min-width: 768px) {
    .modal-dialog {
        width: 45rem;
        margin: 5rem auto;
    }
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.3;
}

.modal-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    pointer-events: auto;
    background: #1a1112;
    padding: 3rem 4rem;
    border-radius: 5px;
}

@media only screen and (max-width: 991px) {
    .modal-content {
        margin: 3rem 2rem;
    }
}

#start-page .dropdown {
    position: relative;
}

#start-page .dropdown-menu {
    position: absolute;
    inset: 100% 0 auto auto !important;
    -webkit-transform: none !important;
    transform: none !important;
    padding: 2rem 1.5rem 1rem;
    z-index: 1000;
    display: none;
    margin: 0.8rem 0 0;
    background: #1a1112;
    -webkit-box-shadow: 0px 12px 30px rgba(45, 45, 45, 0.18);
    box-shadow: 0px 12px 30px rgba(45, 45, 45, 0.18);
    border-radius: 6px;
    width: 28rem;
    text-align: left;
    margin-top: 1rem !important;
}

@media only screen and (max-width: 767px) {
    #start-page .dropdown-menu {
        width: 100%;
    }
}

#start-page .dropdown-menu.show {
    display: block;
}


#start-page .wrap {
    overflow: hidden;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#start-page .content {
    padding-bottom: 5.8rem;
}

@media only screen and (max-width: 991px) {
    #start-page .content {
        padding-bottom: 4.9rem;
    }
}

@media only screen and (max-width: 767px) {
    #start-page .content {
        padding-bottom: 4.7rem;
    }
}

#start-page .content_padding {
    padding-bottom: 10.3rem;
}

@media only screen and (max-width: 991px) {
    .content_padding {
        padding-bottom: 5.9rem;
    }
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#start-page .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0 auto;
    width: 100%;
}

@media only screen and (min-width: 1600px) {
    #start-page .container {
        max-width: 1350px;
    }
}

@media only screen and (max-width: 1599px) {
    #start-page .container {
        max-width: 1140px;
    }
}

@media only screen and (max-width: 1199px) {
    #start-page .container {
        max-width: 970px;
    }
}

@media only screen and (max-width: 991px) {
    #start-page .container {
        max-width: 750px;
    }
}

@media only screen and (max-width: 767px) {
    #start-page .container {
        padding-left: 2rem;
        padding-left: 2rem;
    }
}

.w-100 {
    width: 100%;
}

.d-none {
    display: none !important;
}

@media only screen and (min-width: 768px) {
    .d-md-block {
        display: block !important;
    }
}

@media only screen and (min-width: 992px) {
    .d-lg-block {
        display: block !important;
    }
}

.pos-rel {
    position: relative;
    z-index: 1;
}

.img-fluid {
    display: block;
    max-width: 100%;
}

#start-page .row {
    margin-left: -1.8rem;
    margin-right: -1.8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

#start-page .row > * {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}

#start-page a {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    text-decoration: none;
    color: #fff;
}


.link-underline {
    text-decoration: underline;
}

.link-underline:hover {
    text-decoration: none;
}

#start-page p {
    margin-bottom: 27px;
}

.title_size-sm {
    font-size: 2.6rem;
    line-height: 3rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
}

.title_size-md {
    font-size: 4.5rem;
    line-height: 100%;
    font-weight: 700;
}

@media only screen and (max-width: 991px) {
    .title_size-md {
        font-size: 4rem;
    }
}

@media only screen and (max-width: 767px) {
    .title_size-md {
        font-size: 3rem;
    }

    #start-page p {
        font-size: 22px;
    }
}

.title_size-lg {
    font-weight: 700;
    font-size: 6.5rem;
    line-height: 100%;
}

@media only screen and (max-width: 991px) {
    .title_size-lg {
        font-size: 4.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .title_size-lg {
        font-size: 3.5rem;
    }
}

.title_size-xl {
    font-weight: 800;
    font-size: 90px;
    line-height: 80%;
}

@media only screen and (max-width: 991px) {
    .title_size-xl {
        font-size: 6rem;
    }
}

.text_size-md {
    font-size: 2rem;
}

.text_primary {
    color: #371200;
}

.text_light {
    color: #c1c3c9;
}

.text_dark {
    color: #797c84;
}

.text_danger {
    color: red;
}

.text-normal {
    font-weight: 400;
}

.text-medium {
    font-weight: 500;
}

.text-bold {
    font-weight: 700;
}

.text-extrabold {
    font-weight: 800;
}

.text_upper {
    text-transform: uppercase;
}

.text_center {
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .text_center-sm {
        text-align: center;
    }
}

@media only screen and (max-width: 991px) {
    .text_center-md {
        text-align: center;
    }
}

.text-right {
    text-align: right;
}

@media only screen and (min-width: 1200px) {
    .text-right_xl {
        text-align: right;
    }
}

#start-page .header {
    padding: 1.1rem 0 0.9rem;
    margin-bottom: 0.1rem;
    position: relative;
    z-index: 95;
}

#start-page .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2.2rem;
    font-weight: bold;
    text-transform: uppercase;
}

#start-page .logo:hover {
    opacity: 0.7;
}

.logo__text {
    font-weight: 800;
    margin-left: 0.6rem;
    letter-spacing: 0.025rem;
}

@media only screen and (max-width: 767px) {
    .logo__text {
        line-height: 2.2rem;
    }
}

.nav_1 {
    margin: auto 0.2rem auto auto;
}


@media only screen and (max-width: 767px) {
   .nav_1 {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        z-index: 105;
        background: rgba(20, 12, 10, 0.7);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
    .nav_1.show {
        -webkit-transform: none;
        transform: none;
    }
}

.nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .nav-list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        padding: 2rem;
        z-index: 96;
        position: relative;
    }
}

@media only screen and (min-width: 768px) {
    .nav-list__item:not(:first-child) {
        margin-left: 4.5rem;
    }
    .nav-list__item:last-child {
        margin-left: 3.9rem;
    }
}

@media only screen and (max-width: 767px) {
    .nav-list__item {
        width: 100%;
        text-align: center;
    }
}

.nav-list__link {
    font-weight: 500;
}

@media only screen and (max-width: 767px) {
    .nav-list__link {
        padding: 2rem;
        display: block;
    }
}

.nav-list__link_btn {
    padding: 1.1rem 1.9rem 1.2rem;
    border-radius: 25px;
    border: 2px solid #D44B0E;
    background-color: rgba(0,0,0,0.2);
    display: block;
}

@media only screen and (max-width: 767px) {
    .nav-list__link_btn {
        padding: 1.5rem 1.9rem;
        margin-top: 1.5rem;
    }
}

.nav-list__link_btn:hover {
    border-color: #ff9853;
}

.btn-menu {
    margin-left: auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: center;
    align-self: center;
    position: relative;
    z-index: 95;
}

@media only screen and (min-width: 768px) {
    .btn-menu {
        display: none;
    }
}

.btn-menu.show {
    position: fixed;
    top: 3.3rem;
    right: 1.5rem;
}

.btn-menu.show .btn-menu__line {
    background: #ff9853;
}

.btn-menu__line {
    display: block;
    height: 0.2rem;
    width: 2.2rem;
    background: #fff;
}

.btn-menu__line + .btn-menu__line {
    margin-top: 0.4rem;
}

.close-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@media only screen and (min-width: 768px) {
    .close-menu {
        display: none;
    }
}

.preview {
    position: relative;

}

.preview .bg-preview {
    position: absolute;
    width: 100vw;
    height: auto;
    opacity: 0.7;
    filter: contrast(1.4);
}

@media only screen and (min-width: 1920px) {
    .preview {
        /* padding-bottom: 30rem; */
    }
}

@media only screen and (max-width: 1399px) {
    .preview {
        background-position: 25% 0;
    }
}

@media only screen and (max-width: 991px) {
    .preview {
        background-position: 50% 0;
    }
}

@media only screen and (max-width: 767px) {
    .preview {
        background-position: 78% 0;
        padding-bottom: 32px;
        width: 100vw;
        overflow: hidden;
    }

    .preview .bg-preview {
        width: auto;
        height: 100%;
    }
}

.preview__image {
    position: absolute;
    top: 20px;
    right: 4%;
    margin: 0 -2rem 0 1.4rem;
    z-index: 1;
    width: 55vw;
    max-width: 1000px;
}

@media only screen and (min-width: 3000px) {
    .preview__image {
        /* left: 46%; */
        right: 25%;
    }
}

@media only screen and (max-width: 991px) {
    .preview__image {
        top: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .preview__image {
        position: relative;
        left: 0;
        right: 0;
        margin: -8rem auto 0;
        top: 0;
        width: 95vw;
    }
}

.preview__slot {
    position: absolute;
    left: -14%;
    right: 0;
    margin: 0 auto;
    top: 55%;
    width: 28.5%;
}

.preview__slot._active {
    -webkit-animation: scale 2s infinite linear;
    animation: scale 2s infinite linear;
}

.preview__coin-1 {
    position: absolute;
    width: 15.5%;
    top: 45.6%;
    left: 13.4%;
}

.preview__coin-2 {
    position: absolute;
    width: 9%;
    top: 69.9%;
    left: 44.7%;
}

.preview__coin-3 {
    position: absolute;
    width: 8%;
    top: 52%;
    left: 74.8%;
}

.preview__title {
    margin-bottom: 32px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    display: inline-block;
}

.preview__title:hover {
    -webkit-transform: scale(1.1) !important;
    transform: scale(1.1) !important;
}


.preview__text {
    width: 50%;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 300;
    position: relative;
    z-index: 3;
    padding-top: 14vw;
    padding-bottom: 10.5vw;
}

.preview__text p {
    font-size: 28px;
    max-width: 440px;
    font-weight: 500;
    line-height: 33px;

}

@media only screen and (max-width: 1919px) {
    .preview__text {
        /* padding: 13rem 0 59rem; */
    }
}

@media only screen and (max-width: 1599px) {
    .preview__text {
        /* padding: 157px 0 59rem; */
    }
}

@media only screen and (max-width: 1399px) {
    .preview__text {
        /* padding: 7rem 0 40rem; */
    }
}

@media only screen and (max-width: 1199px) {
    .preview__text {
        width: 60%;
        /* padding: 11rem 0 27rem; */
    }
}

@media only screen and (max-width: 991px) {
    .preview__text {
        width: 70%;
        /* padding: 8rem 0 22rem; */
    }
}

@media only screen and (max-width: 767px) {
    .preview__text {
        width: 100%;
        text-align: center;
        padding: 4rem 0;
        padding-top: 64px;
    }

    .preview__text p {
        max-width: none;
    }
}

.preview__btn {
    margin: 0 -0.2rem;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.preview__btn:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
    .preview__btn {
        margin-top: 0;
    }
}

@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.btn {
    position: relative;
    padding: 19px 45px;
    border-radius: 35px;
    font-size: 22px;
    display: inline-block;
    color: #FFF;
    text-decoration: none;
    background: linear-gradient(180deg, #C73E00 0%, #FF4F00 100%);
    border-bottom: 5px solid #000000;
    box-shadow: 0px 0px 12px 0px #00000080 inset;
    text-shadow: 0px -2px #eb843f;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@media only screen and (max-width: 767px) {
    .btn {
        font-size: 2rem;
    }
}

.btn:hover {
    color: #fff;
    background-color: #eb843f;
}

#start-page .icon-arrow {
    position: absolute;
    width: 7.7rem;
    height: 8.4rem;
    background: url(../../public/sp_img/arrow.png) 50% 50% no-repeat;
    background-size: contain;
    bottom: -4.9rem;
    right: -3.9rem;
    display: none;
}

.anim__show {
    -webkit-transform: translate(0, -5rem);
    transform: translate(0, -5rem);
    opacity: 0;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.anim__show._active,
._active .anim__show {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}

.anim__showR {
    -webkit-transform: translate(5rem, 0);
    transform: translate(5rem, 0);
    opacity: 0;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.anim__showR._active,
._active .anim__showR {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}

.anim__showL {
    -webkit-transform: translate(-5rem, 0);
    transform: translate(-5rem, 0);
    opacity: 0;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.anim__showL._active,
._active .anim__showL {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}

.anim__showText {
    -webkit-transform: translate(0, -5rem);
    transform: translate(0, -5rem);
    opacity: 0;
    -webkit-transition: all 1.5s ease 0s;
    transition: all 1.5s ease 0s;
}

.anim__showText._active,
._active .anim__showText {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}

.anim__showWidth.social__list:after {
    width: 0;
    -webkit-transition: all 1.5s ease 0s;
    transition: all 1.5s ease 0s;
}

.anim__showWidth._active.anim__showWidth.social__list:after,
._active .anim__showWidth.social__list:after {
    width: 64%;
}

.anim__showOpacity {
    opacity: 0;
    -webkit-transition: all 1.5s ease 0s;
    transition: all 1.5s ease 0s;
}

.anim__showOpacity._active,
._active .anim__showOpacity {
    opacity: 1;
}

.anim__showOpacityF {
    opacity: 0;
    -webkit-transition: all 1.5s ease 0.5s;
    transition: all 1.5s ease 0.5s;
}

.anim__showOpacityF._active,
._active .anim__showOpacityF {
    opacity: 1;
}

.anim__showOpacityS {
    opacity: 0;
    -webkit-transition: all 1.5s ease 0.8s;
    transition: all 1.5s ease 0.8s;
}

.anim__showOpacityS._active,
._active .anim__showOpacityS {
    opacity: 1;
}

.anim__showOpacityT {
    opacity: 0;
    -webkit-transition: all 1.5s ease 1s;
    transition: all 1.5s ease 1s;
}

.anim__showOpacityT._active,
._active .anim__showOpacityT {
    opacity: 1;
}

.anim__showRotate {
    -webkit-transform: translate(-20%, 0) rotate(180deg);
    transform: translate(-20%, 0) rotate(180deg);
    opacity: 0;
    -webkit-transition: all 1.5s ease 0s;
    transition: all 1.5s ease 0s;
}

.anim__showRotate._active,
._active .anim__showRotate {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
    opacity: 1;
}

@media only screen and (max-width: 767px) {
    .anim-items {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        -webkit-transition: none;
        transition: none;
    }
}

.advantages {
    position: relative;
    z-index: 6;
    padding-top: 53px;
    padding-bottom: 36px;
}

.advantages .title_size-sm {
    font-size: 26px;
    font-weight: 700;
}

#start-page .advantages__headeing p {
    margin-bottom: 0;
}

/* @media only screen and (max-width: 1919px) {
    .advantages {
        margin: -40rem 0 5.2rem;
    }
}

@media only screen and (max-width: 1599px) {
    .advantages {
        margin-top: -45rem;
    }
}

@media only screen and (max-width: 1399px) {
    .advantages {
        margin-top: -34rem;
    }
}

@media only screen and (max-width: 1199px) {
    .advantages {
        margin-top: -20rem;
    }
}

@media only screen and (max-width: 991px) {
    .advantages {
        margin: -10rem 0 3rem;
    }
} */

@media only screen and (max-width: 767px) {
    .advantages {
        /* margin: 32px 0 3rem; */
        /* margin-top: 0; */
        padding-top: 32px;
    }
}

.advantages__image {
    width: 50.5%;
    top: 0;
    left: -8px;
    /* position: absolute; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
}

.advantages__image img {
    max-height: 595px;
}

@media only screen and (max-width: 767px) {
    .advantages__image {
        display: none;
    }
}

/* .advantages__image img {
    max-width: 90%;
} */

@media only screen and (max-width: 991px) {
    .advantages__image img {
        max-width: 90%;
    }
}

.advantages__text {
    width: 48.5%;
    margin-left: auto;
    /* padding-top: 29rem; */
}

@media only screen and (max-width: 1919px) {
    /* .advantages__text {
        padding-top: 19rem;
    } */
}

@media only screen and (max-width: 1399px) {
    /* .advantages__text {
        padding-top: 14rem;
    } */
}

@media only screen and (max-width: 1199px) {
    .advantages__text {
        width: 52.5%;
        /* padding-top: 7rem; */
    }
}

@media only screen and (max-width: 991px) {
    .advantages__text {
        width: 58.5%;
        padding-top: 0;
    }
}

@media only screen and (max-width: 767px) {
    .advantages__text {
        width: 100%;
    }
}

.advantages__headeing {
    margin-bottom: 13px;
}

@media only screen and (max-width: 767px) {
    .advantages__headeing {
        text-align: center;
    }
}

.advantages-item {
    margin: 0 -1rem 17px 0;
    background: #292B39;
    box-shadow: 2px 2px 2px 0px #9B9796 inset;
    border-radius: 23px;
    padding: 24px 20px;

}

.advantages-item:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .advantages-item {
        margin-right: 0;
    }
}

.advantages-item__icon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 1.5rem;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* background: rgba(255, 255, 255, 0.1); */
}

.advantages-item__text {
    font-size: 1.6rem;
    color: #c1c3c9;
    font-weight: 300;
}

.advantages-item__text p {
    margin-bottom: 0 !important;
    line-height: 100%;
}

.advantages-item__title {
    font-weight: 500;
    color: #fff;
    margin-bottom: 0.3rem;
}

.advantages-item p {
    margin: 0;
}

.about {
    position: relative;
    z-index: 4;
    padding-bottom: 14rem;
}

.about .container {
    justify-content: space-between;
}

.about .container .about-img {
    padding-top: 85px;
    margin-right: -28px;
    max-width: 50.5%;
    /* max-width: 521px; */
}

@media only screen and (max-width: 1599px) {
    .about {
        padding-bottom: 5rem;
    }
}

@media only screen and (max-width: 1399px) {
    .about {
        padding-bottom: 8.8rem;
    }
}

@media only screen and (max-width: 991px) {
    .about {
        padding-bottom: 5rem;
    }
}

@media only screen and (max-width: 767px) {
    .advantages-item__text p {
        font-size: 16px !important;
    }

    .about .container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .about .container .about-img {
        max-width: 80vw;
        width: 100%;
        margin: 0;
        padding-top: 36px;
    }
    
}

.about__text {
    width: 49%;
    padding-top: 9rem;
}


@media only screen and (max-width: 1399px) {
    .about__text {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .about__text {
        width: 100%;
        padding-top: 4.5rem;
    }
}

@media only screen and (min-width: 1600px) {
    .about__text {
        padding-top: 16rem;
    }
}

.about__heading {
    margin-bottom: 3.5rem;
}

#start-page .about__heading p {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 43px;
}

.about__heading .title_size-lg {
    margin-bottom: 14px;
}

.about-item {
    display: flex;
    align-items: center;
    gap: 32px;
    padding: 11px 20px;
    box-shadow: 2px 2px 2px 0px #9B9796 inset;
    border-radius: 23px;
    background: #292B39;
    color: #FF9853;
    font-size: 16px;
    font-weight: 700;

}

.about-item:not(:last-child) {
    margin-bottom: 36px;
}

.about-item__text {
    max-width: 315px;
}

@media only screen and (max-width: 991px) {

    .about-item:not(:last-child) {
     margin-bottom: 17px;
    }

    .about-item__text {
        max-width: none;
    }
}


.about-item__image {
    width: 64px;
    min-width: 64px;
}

/* @media only screen and (max-width: 991px) {
    .about-item__image {
        min-width: 10rem;
        width: 10rem;
    }
} */

/* .about-item__image:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 12rem;
    height: 12rem;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    border-radius: 50%;
    opacity: 0.3;
} */

.about-item__image_blue:before {
    background: #2b62a7;
}

.about-item__image_yellow:before {
    background: #8b7721;
}

.about-item__image_violet:before {
    background: #2f2deb;
}

.about-item__image_white:before {
    background: #a6bee7;
}

.design {
    /* background: url(../../public/sp_img/bg-design.jpg) 50% 0 no-repeat; */
    font-weight: 300;
    /* padding-top: 17.5rem;
    margin-top: -13.5rem; */
    position: relative;
    z-index: 3;
    font-size: 1.8rem;
    line-height: 2.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 127px;
}

.design-img {
    width: 100vw;
    min-width: 700px;
    /* max-width: 2000px; */
    /* max-height: 400px; */
}

@media only screen and (max-width: 1199px) {
    .design {
        background-size: auto 100%;
        padding-top: 0;
        margin-top: 0;
    }
}

@media only screen and (max-width: 1199px) {
    .design {
        z-index: 5;
    }
}

@media only screen and (max-width: 767px) {
    .design {
        font-size: 1.6rem;
    }

    
}

.design__title {
    margin-bottom: 1.1rem;
}

.design__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: -0.9rem 0 -0.9rem 28%;
}

@media only screen and (max-width: 767px) {
    .design__image {
        margin-top: 0;
    }
}

.work {
    /* background: url(../../public/sp_img/bg-work.jpg) 50% 0 no-repeat;
    background-size: cover; */
    padding: 134px 0 145px;
    position: relative;
    z-index: 2;
}



/* @media only screen and (max-width: 767px) {
    .work {
        padding: 5.2rem 0 3rem;
    }
} */

.work__row {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    position: relative;
    display: flex;
    padding-top:145px;
}

/* @media only screen and (min-width: 1920px) {
    .work__row {
        margin-left: -15.3%;
        margin-right: -15.3%;
    }
}

@media only screen and (max-width: 767px) {
    .work__row {
        margin-top: 2.6rem;
    }
} */

.work-item {
    max-width: 713px;
    width: 49.5vw;
}

.work-item:first-child, .work-item:last-child {
    position: absolute;
}

.work-item:first-child {
    left: -14%;
    top: 11%;
}

.work-item:last-child {
    right: -14%;
    bottom: -14%;
}

@media(max-width: 1439px) {
    .work .container {
        max-width: none !important;
        padding: 0;

    }

    .work {
        padding-bottom: 10vw;
    }

    .work__row {
        padding-top: 10vw;
    }

    .work-item:first-child {
        left: 15px;
    }
    .work-item:last-child {
        right: 15px;
    }
}

@media(max-width: 767px) {
    .work {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .work__row {
        flex-direction: column;
        gap: 10px;
        padding-top: 26px;
        align-items: center;
    }

    .work-item {
        max-width: 530px;
        width: 100%;
    }

    .work-item:first-child, .work-item:last-child {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.partners {
    position: relative;
    z-index: 1;
    margin-top: 63px;
}

/* .partners:before {
    content: '';
    display: block;
    position: absolute;
    top: -16.1rem;
    left: 0;
    right: 0;
    z-index: -1;
    background: url(../../public/sp_img/bg-partners.jpg) 50% 0 no-repeat;
    height: 60.8rem;
} */

.partners-group {
    margin: 66px 0 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 3.6rem 2rem 1.3rem;
    background: #292B39;
    -webkit-box-shadow: 2px 2px 2px 0px #9B9796 inset;
    box-shadow: 2px 2px 2px 0px #9B9796 inset;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 24px;
}

@media only screen and (max-width: 991px) {
    .partners {
        margin-top: 0;
    }
    .partners-group {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 767px) {
    .partners-group {
        margin: 2.6rem 0 3rem;
    }
}

.partners-item {
    margin: 0 2.15rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20%;
}

.partners-item:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
    .partners-item {
        width: 40%;
        margin: 0 1rem 2rem;
    }
}

#start-page .footer {
    padding: 3.3rem 0 2.9rem;
    border-top: 1px solid #241f21;
    position: relative;
    z-index: 2;
}

@media only screen and (max-width: 767px) {
    #start-page .footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.footer__logo {
    margin-right: 7.2rem;
}

@media only screen and (max-width: 767px) {
    .footer__logo {
        margin: 0 auto 3rem;
    }
}

.footer__logo:hover {
    opacity: 0.7;
    color: #fff;
}

.footer__logo .logo__text {
    margin: 0.5rem -0.2rem 0;
}

.footer-nav {
    margin-top: 0.3rem;
}

.footer-nav__item {
    position: relative;
}

.footer-nav__item:not(:last-child) {
    margin-right: 2.2rem;
    padding-right: 1.7rem;
}

.footer-nav__item:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border-right: 1px solid #231e21;
    height: 1.2rem;
}

.footer-nav__link {
    color: #a2918c;
    font-weight: 700;
    font-size: 1.6rem;
}

.social-list {
    margin: 0 -0.1rem 0 auto;
}

@media only screen and (max-width: 767px) {
    .social-list {
        margin: 3rem auto 0;
    }
}

.social-list__link {
    margin-left: 1.6rem;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .social-list__link {
        margin: 0 0.8rem;
    }
}

.social-list__link:hover {
    opacity: 0.7;
}

.form-group {
    margin-bottom: 2rem;
}

.form-control {
    font-family: inherit;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 35px;
    padding: 1.65rem 2rem;
    width: 100%;
    font-size: 17px;
    line-height: 2rem;
    font-weight: 500;
    background: transparent;
    color: #fff;
}

.form-control:focus {
    border-color: rgba(255, 255, 255, 0.8);
}

.form-control.has-error {
    border-color: red;
}

.form-control.has-error::-webkit-input-placeholder {
    color: red !important;
}

.form-control.has-error::-moz-placeholder {
    color: red !important;
}

.form-control.has-error:-moz-placeholder {
    color: red !important;
}

.form-control.has-error:-ms-input-placeholder {
    color: red !important;
}

.error-message {
    font-size: 1.2rem;
    margin-top: 0.5rem;
}

.pass-view {
    position: absolute;
    top: 2.2rem;
    right: 2rem;
    width: 1.9rem;
    height: 1.2rem;
    background-image: url(../../public/sp_img/view.svg);
}

#start-page {
    background-image: url(../../public/sp_img/newDesign/Background.webp);
    background-size: cover;
}

#start-page .logo img {
    width: 220px;
    height: auto;
}

#start-page .hero-block {
    /* position: relative; */
}

#start-page .hero-block .container {
    display: flex;
}


.start-page-line {
    background: linear-gradient(90deg, rgba(232, 196, 4, 0) -0.03%, #F5D802 49.97%, rgba(232, 196, 4, 0) 99.97%);
    height: 6px;
}