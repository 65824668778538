.layout {
    --width-sidebar: 260px;
    display: grid;
    grid-template-columns: var(--width-sidebar) 1fr;
    height: 100vh;
    transition: grid-template-columns 0.3s;
}

.content-wrapper {
    position: relative;
}

.content-wrapper {
    position: relative;
}

@media (max-width: 1200px) {
    .layout {
        --width-sidebar: 0px;
    }
}

@media (min-width: 1200px) {
    .menu-collapsed .layout {
        --width-sidebar: 84px;
    }
}

.page-wrapper {
    padding-top: calc(77px + var(--warn-height, 0px));
}
